<template>
  <div class="marvellous">
    <div class="marvebox">
      <h2>精彩瞬间</h2>
      <div class="span"></div>
      <div class="tabs">
        <!--<div class="picbox" @click="gopicclick2()">-->
        <div class="picbox" @click="gopicclick1()">
          <p>2024年全国总决赛-北京</p>
          <img src="../../assets/2023/pcnavber.png" class="img1" />
          <div type="primary" class="btn">
            挑战自我 赛出精彩 <br />
            点击进入大赛图片直播
          </div>
        </div>
        <div class="picbox padtop">
          <p @click="opendialogHandle(3,'2023年郑州复赛')">2023年郑州复赛</p>
        </div>
        <div class="picbox padtop">
          <p @click="opendialogHandle(2,'2023年呼和浩特复赛')">2023年呼和浩特复赛</p>
        </div>
        <div class="picbox padtop">
          <p @click="opendialogHandle(1,'2022年决赛精彩瞬间')">2022年决赛精彩瞬间</p>
        </div>
      </div>
    </div>
    <footerCard class="footer"></footerCard>
    <div class="goup">
      <a href="#">
        <i class="el-icon-caret-top"></i>
      </a>
    </div>
    <el-dialog
      :title="picTitle"
      :top="dialogtop"
      class="mydialog"
      @close="closeDialogHandle"
      :visible.sync="dialogVisible1"
      :width="dialogWidth"
    >
      <div class="mar-box" v-if="marvellousList.length > 0">
        <div class="box" v-for="item in marvellousList" :key="item.id">
          <el-image
            style="height: 100%"
            :src="picURL + item.img_url"
            :preview-src-list="srcList"
            lazy
          >
          </el-image>
        </div>
        <el-button
          :disabled="disabled"
          type="text"
          class="footertitle"
          @click="moreHandle()"
          :loading="loading"
          >{{ value }}</el-button
        >
      </div>
      <div class="notpic" v-else>暂无图片~</div>
    </el-dialog>
  </div>
</template>
<script>
import footerCard from "../../components/footerCard.vue";
import { getMomentsList } from "../API/marvellous";
import getShareInfo from "../../assets/js/share";
export default {
  data() {
    return {
      dialogVisible1: false,
      dialogWidth: "80%",
      dialogtop: "10vh",
      picshow: false,
      activeName: "1",
      loading: false,
      value: "点击加载更多",
      title:"2022",
      picTitle:"",//图片弹出层的标题
      pageData: {
        page: 1,
        limit: 8,
        phase: localStorage.phase,
      },
      total: 0,
      marvellousList: [], //2022
      srcList: [],
      disabled: false,
      picURL: "http://testgyyc.gytcare.com/",
      shareData: {
        title: "精彩瞬间",
        desc: "挑战自我 赛出精彩,点击进入大赛图片直播",
        link: window.location.href,
        imgUrl: "https://jnds-static.oss-cn-beijing.aliyuncs.com/pcnavber.jpg",
      },
    };
  },
  created() {
    getShareInfo(this.shareData);
    let myserve = window.location.origin.split("//")[1];
    if (myserve.split(":")[0] == "localhost") {
    } else {
      this.picURL = window.location.origin;
    }
    if (document.body.clientWidth < 540) {
      this.dialogWidth = "100%";
      this.dialogtop = "0";
    }
  },
  methods: {
    opendialogHandle(phase,title) {
      this.activeName = phase;
      this.dialogVisible1 = true;
      this.picTitle = title
      this.getMomentsListHandle();
    },
    closeDialogHandle() {
      this.dialogVisible1 = false;
      this.pageData.page = 1;
      this.marvellousList = [];
      this.srcList = []
    },
    // 2024年决赛外链
    gopicclick1() {
      window.open("https://alltuu.cc/r/RNryQn/");
    },
    gopicclick2() {
      window.open("https://v.alltuu.com/r/VVniim/");
    },

    handleClick(tab, event) {
      this.marvellousList = [];
      this.getMomentsListHandle();
    },
    moreHandle() {
      this.loading = true;
      this.value = "加载中...";
      this.pageData.page++;
      this.getMomentsListHandle();
      this.loading = false;
      this.value = "点击加载更多";
    },
    // 获取图片列表
    async getMomentsListHandle() {
      this.pageData.phase = this.activeName;
      const res = await getMomentsList(this.pageData);
      if (res.data.code == "0000") {
        this.total = res.data.count;
        if (res.data.data.length === 0) {
          this.disabled = true;
          this.value = "已经全部加载完毕";
        } else {
          this.disabled = false;
          this.value = "点击加载更多";
          this.marvellousList.push(...res.data.data);
          res.data.data.forEach((item) => {
            this.srcList.push(this.picURL + item.img_url);
          });
        }
      }
    },
  },
  components: {
    footerCard,
  },
};
</script>
<style scoped lang="scss">
.marvellous {
  // ::v-deep .el-dialog {
  //   @media screen and (max-width: 520px) {
  //     height: 100vh;
  //   }
  // }
  .notpic{
    text-align: center;
    color: #666;
  }
  .padtop {
    padding-top: 50px;
  }
  .mydialog {
    height: 100vh;
  }
  .picbox {
    text-align: center;
    cursor: pointer;
    position: relative;
    p {
      padding: 10px;
      background: #05258e;
      border-radius: 10px 10px 0 0;
      color: #fff;
      @media screen and (max-width: 520px) {
        font-size: 16px;
      }
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 0 0 10px 10px;
    }
    .btn {
      border-radius: 0 0 10px 10px;
      background: rgb(0 0 0 / 60%);
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 5px;
      line-height: 1.5;
      padding: 10px 0;
      color: #fff;
      text-align: center;
      @media screen and (max-width: 520px) {
        font-size: 12px;
        padding: 5px 0;
      }
    }
  }
  position: relative;
  ::v-deep .el-tabs__item {
    font-size: 20px !important;
    @media screen and (max-width: 520px) {
      font-size: 14px !important;
      width: 50%;
      text-align: center;
    }
  }
  ::v-deep .el-tabs__nav {
    @media screen and (max-width: 520px) {
      width: 100%;
    }
  }
  ::v-deep .el-tabs__item:hover {
    color: #05258e !important;
  }
  ::v-deep .el-tabs__item.is-active {
    color: #05258e !important;
    font-weight: 600;
  }
  ::v-deep .el-tabs__active-bar {
    background: #05258e;
  }
  ::v-deep .el-image__error {
    background: #fff;
  }
  .goup {
    display: none;
    @media screen and (max-width: 520px) {
      display: block;
      position: fixed;
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      bottom: 30px;
      right: 20px;
      background-color: #fff;
      box-shadow: 0 0 6px rgb(0 0 0 / 12%);
      border-radius: 50%;
      text-align: center;
      color: #409eff;
      font-size: 20px;
    }
  }
  .nodata {
    text-align: center;
    padding: 80px 0;
  }
  background-image: url("../../assets/image/index-img/bg.webp"),
    url("../../assets/image/index-img/bg2.webp");
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  background-color: #f9f9f9;
  padding-top: 84px;
  @media screen and (max-width: 520px) {
    // background: none;
    background-color: #fff;
    padding: 0;
  }
  h2 {
    font-size: 26px;
    text-align: center;
    color: #05258e;
    margin-bottom: 10px;
    @media screen and (max-width: 520px) {
      padding-top: 20px;
    }
  }
  .span {
    display: block;
    margin: 0px auto;
    width: 24px;
    height: 4px;
    background: #05258e;
    border-radius: 2px;
    margin-bottom: 38px;
    @media screen and (max-width: 520px) {
      margin-bottom: 18px;
    }
  }
  .tabs {
    width: 80%;
    margin: auto;
    font-size: 20px;
  }
  .mar-box {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    margin: auto;
    padding: 15px;
    box-sizing: border-box;
    padding-bottom: 40px;
    margin-bottom: 20px;
    position: relative;
    @media screen and (max-width: 520px) {
      width: 100%;
    }
    .footertitle {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }
    .box {
      height: 220px;
      background-color: #fff;
      padding: 5px;
      flex-basis: 24%;
      text-align: center;
      overflow: hidden;
      box-sizing: border-box;
      @media screen and (max-width: 520px) {
        flex-basis: 50%;
        height: 100px;
      }
    }
  }
  .footer {
    display: block;
    margin-top: 100px;
  }
}
</style>
